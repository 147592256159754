import { Box, Tabs } from '@bigcommerce/big-design';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const TabIds = {
    HOME: 'home'
};

export const TabRoutes = {
    [TabIds.HOME]: '/'
};

const HeaderTypes = {
    GLOBAL: 'global',
    INNER: 'inner',
};

const Header = () => {
    const [activeTab, setActiveTab] = useState<string>('');
    const [headerType, setHeaderType] = useState<string>(HeaderTypes.GLOBAL);
    const router = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        const pathStart = '/' + pathname.split('/')[1];
        // Check if new route matches TabRoutes
        const tabKey = Object.keys(TabRoutes).find(key => TabRoutes[key] === pathStart);

        // Set the active tab to tabKey or set no active tab if route doesn't match (404)
        setActiveTab(tabKey ?? '');
        setHeaderType(HeaderTypes.GLOBAL);
    }, [pathname]);

    const items = [
        { id: TabIds.HOME, title: 'Home' }
    ];

    // const getActiveInnerTab = () => {
    //     if (activeTab === TabIds.PRODUCTS || pathname.startsWith(TabRoutes[TabIds.PRODUCTS])) {
    //         return <ProductsInnerHeader />;
    //     } else if (activeTab === TabIds.CUSTOMERS || pathname.startsWith(TabRoutes[TabIds.CUSTOMERS])) {
    //         return <CustomersInnerHeader />;
    //     } else if (activeTab === TabIds.SCRIPTS || pathname.startsWith(TabRoutes[TabIds.SCRIPTS])) {
    //         return <SciptsInnerHeader />;
    //     } else {
    //         throw new Error(`No InnerTab configured for ${activeTab}.`);
    //     }
    // };

    // if (headerType === HeaderTypes.INNER) return getActiveInnerTab();

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);

        return router.push(TabRoutes[tabId]);
    };

    return (
        <Box marginBottom="xxLarge">
            <Tabs
                activeTab={activeTab}
                items={items}
                onTabClick={handleTabClick}
            />
        </Box>
    );
};

export default Header;
