import { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Box, GlobalStyles } from '@bigcommerce/big-design';
import { parse } from 'query-string';
import Header from 'components/header';
import { Home } from 'pages';

import SessionProvider from 'context/session';
import { bigCommerceSDK } from 'scripts/bcSdk';

const App = () => {
  const { search } = useLocation();
  const { context } = parse(search);

  useEffect(() => {
      // Keeps app in sync with BC (e.g. heatbeat, user logout, etc)
      if (context) bigCommerceSDK(context);
  }, [context]);

  return (
      <>
          <GlobalStyles />
          <Box marginHorizontal="xxxLarge" marginVertical="xxLarge">
              <Header />
              <SessionProvider>
                <Switch>
                  <Route path='/'>
                    <Home context={context} />
                  </Route>
                </Switch>
              </SessionProvider>
          </Box>
      </>
  );
};

export default App;
