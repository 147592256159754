import { Box, Flex, Panel, FlexItem } from '@bigcommerce/big-design';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'components/error';
import Loading from 'components/loading';
import { useSession } from 'context/session';
import { RatesRequestForm } from 'components/forms';
import { RateRequestFormData } from 'types';

const Index = ({ context }: { context: string | string[] }) => {
    const { setContext } = useSession();
    const [responseData, setResponseData] = useState();
    const [responseError, setResponseError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (Array.isArray(context)) setContext(context[0]);
        else if (context) setContext(context);
    }, [context, setContext]);

    const sendRequest = async (data: RateRequestFormData) => {
        let response: Response;

        setIsLoading(true);

        try {
            response = await fetch(
                '/api/shippingrates/test',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                });
            const ratesResponse = await response.json();
            setResponseData(ratesResponse);
            setResponseError(null);
        } catch (error) {
            console.error(`Error Executing Shipping Rates Request: `, error);
            setResponseData(null);
            setResponseError(error);
        }

        setIsLoading(false);

        return false;
    }

    if (typeof context === 'undefined' || context === null) {
        return <ErrorMessage error={new Error('This App is meant to be accessed from within a BigCommerce store. Please install and try again.')} />
    }

    return (
        <Panel header='Rates Tester'>
            <Flex justifyContent={'stretch'} flexColumnGap={'3rem'}>
                <FlexItem flexGrow={1}>
                    <Panel header='Request'>
                        <RatesRequestForm onSubmit={sendRequest} />
                    </Panel>
                </FlexItem>
                <FlexItem flexGrow={2}>
                    <Panel header='Response'>
                        {isLoading
                            ? <Loading/>
                            : responseError
                                ? <ErrorMessage error={responseError} />
                                : responseData
                                    ? <Box backgroundColor='secondary20' border='box' borderRadius='normal' padding='medium'>
                                        <pre>
                                            {JSON.stringify(responseData, null, 2)}
                                        </pre>
                                    </Box>
                                    : <p>Execute Request first</p>
                        }
                    </Panel>
                </FlexItem>
            </Flex>
        </Panel>
    );
};

const StyledBox = styled(Box)`
    min-width: 10rem;
`;

export default Index;
