import { Button, Checkbox, Flex, FormGroup, Input, Panel, Form as StyledForm, Textarea, Select, Fieldset, FormControlLabel, Radio, Datepicker, ButtonGroup, ButtonGroupAction, Box } from '@bigcommerce/big-design';
import { AddIcon, DeleteIcon, EditIcon, CheckIcon, CloseIcon, RestoreIcon } from '@bigcommerce/big-design-icons';
import { ChangeEvent, FormEvent, useState, createRef, forwardRef, RefObject } from 'react';
import { RateRequestFormData, ShipmentLineItemFormData, StringKeyValue } from 'types';
import { WysiwygEditor } from './wysiwygEditor';

const FormErrors = {
    first_name: 'First Name is required',
    last_name: 'Last Name is required',
    email: 'Email Address is required',
};

const DefaultLineItemData: ShipmentLineItemFormData = {
    itemSku: '',
    quantity: 1
}

const DefaultFormData: RateRequestFormData = {
    webServiceUrl: 'http://abcwebservices.associntranet.com/ABC_Freight/ABC_Freight.asmx',
    methodName: 'Get_Rates',
    // userId: '',
    // userDomain: '',
    postalCode: '',
    orderNumber: 'WebSite',
    country: 'USA',
    // billingTerms: '',
    // freightTerms: '',
    addressType: '',
    // distributionCenterOverride: '',
    lineItems: [DefaultLineItemData]
}

const Form = ({onSubmit}: { onSubmit: (form: RateRequestFormData) => void }) => {
    const [formData, setFormData] = useState<RateRequestFormData>(DefaultFormData);
    const [errors, setErrors] = useState<StringKeyValue>({});
    const [isLoading, setIsLoading] = useState(false);

    const { webServiceUrl, methodName, userId, userDomain, postalCode, orderNumber, country, billingTerms, freightTerms, addressType, distributionCenterOverride, lineItems } = formData;

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name: formName, value } = event?.target;
        setFormData(prevForm => ({ ...prevForm, [formName]: value }));

        // Add error if it exists in FormErrors and the input is empty, otherwise remove from errors
        !value && FormErrors[formName]
            ? setErrors(prevErrors => ({ ...prevErrors, [formName]: FormErrors[formName] }))
            : setErrors(({ [formName]: removed, ...prevErrors }) => ({ ...prevErrors }));
    };

    const handleItemChange = (index: number, item: ShipmentLineItemFormData) => {
        setFormData(prevForm => {
            const newForm = {...prevForm};
            newForm.lineItems[index] = item;
            return newForm;
        });
    }

    const handleItemDelete = (index: number) => {
        setFormData(prevForm => {
            const newForm = {...prevForm};
            newForm.lineItems.splice(index, 1);
            return newForm;
        });
    }

    const handleItemAdd = () => {
        const lastItem = lineItems[lineItems.length - 1];

        setFormData(prevForm => {
            const newForm = {...prevForm};
            newForm.lineItems.push(DefaultLineItemData);
            return newForm;
        });
    }

    // const handleDateChange = (value: Date) => {
    //     setFormData(prevForm => ({ ...prevForm, shipDate: value }));
    // };

    // const handleTimeChange = (value: Time) => {
    //     setFormData(prevForm => ({ ...prevForm, shipDate: value }));
    // };

    const handleSelectChange = (value: string, formName: string) => {
        setFormData(prevForm => ({ ...prevForm, [formName]: value }));
    };

    const handleReset = () => {
        setFormData(DefaultFormData);
    }

    const handleSubmit = async (event: FormEvent<EventTarget>) => {
        event.preventDefault();
        setIsLoading(true);

        // If there are errors, do not submit the form
        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors) return;

        await onSubmit(formData);

        setIsLoading(false);
        // onCancel();
    };

    const ref: RefObject<HTMLInputElement> = createRef();
    return (
        <StyledForm onSubmit={handleSubmit} fullWidth={true}>
            {/* <Panel header="Request Data"> */}
                <FormGroup>
                    <Input
                        // ref={ref}
                        type="text"
                        error={errors?.first_name}
                        label="WebService Url (WSURL)"
                        name="webServiceUrl"
                        required
                        value={webServiceUrl}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="text"
                        error={errors?.last_name}
                        label="Method Name (METHOD)"
                        name="methodName"
                        required
                        value={methodName}
                        onChange={handleChange}
                    />
                </FormGroup>
                {/* <FormGroup>
                    <Input
                        type="text"
                        error={errors?.first_name}
                        label="User Id (USERID)"
                        name="userId"
                        value={userId}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        error={errors?.last_name}
                        label="User Domain (DOMAIN)"
                        name="userDomain"
                        value={userDomain}
                        onChange={handleChange}
                    />
                </FormGroup> */}
            {/* </Panel> */}

            {/* <Panel header="Destination"> */}
                <FormGroup>
                    <Input
                        type="text"
                        error={errors?.first_name}
                        label="Postal Code (ZIPCODE)"
                        name="postalCode"
                        required
                        value={postalCode}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        error={errors?.last_name}
                        label="Country (COUNTRY)"
                        name="country"
                        required
                        value={country}
                        onChange={handleChange}
                    />
                </FormGroup>
                {/* <FormGroup>
                    <Input
                        type="text"
                        label="Address Type (ADRTYP)"
                        name="addressType"
                        value={addressType}
                        onChange={handleChange}
                    />
                </FormGroup> */}
                {/* <FormGroup>
                    <Select
                        label="Address Type (ADRTYP)"
                        name="addressType"
                        value={addressType}
                        options={[
                            { value: 'C', content: 'Commercial' },
                            { value: 'R', content: 'Residential' }
                        ]}
                        onOptionChange={(value: string) => handleSelectChange(value, "addressType")}
                    />
                </FormGroup> */}
                <Fieldset>
                    <FormControlLabel>Address Type (ADRTYP)</FormControlLabel>
                    <FormGroup>
                        <Radio
                            label={'Commercial'}
                            name={'addressType'}
                            checked={addressType === 'C'}
                            value={'C'}
                            onChange={handleChange}
                        />
                        <Radio
                            label={'Residential'}
                            name={'addressType'}
                            checked={addressType === 'R'}
                            value={'R'}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Fieldset>
            {/* </Panel> */}

            {/* <Panel header='Shipment Info'>
                <FormGroup>
                    <Input
                        type="text"
                        label="Billing Terms (BILLTERM)"
                        name="billingTerms"
                        value={billingTerms}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="text"
                        label="Freight Terms (FRTTERM)"
                        name="freightTerms"
                        value={freightTerms}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="text"
                        label="Distribution Center Override (DCNOVR)"
                        name="distributionCenterOverride"
                        value={distributionCenterOverride}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Datepicker
                        dateFormat="MMMM d, yyyy"
                        label="Ship Date (SHPDT)"
                        onDateChange={handleChange}
                        value={new Date().toISOString()}
                    />
                </FormGroup>
            </Panel> */}

            <Panel header='Items'>
                {lineItems.map((item, i) => (
                    <LineItem
                        key={`lineitem-${i}`}
                        onEdit={(updatedItem) => handleItemChange(i, updatedItem)}
                        onDelete={() => handleItemDelete(i)}
                        item={item}
                        isFirst={i === 0}
                        isLast={i == (lineItems.length - 1)}
                    />
                ))}

                <Flex>
                    <Button iconOnly={<AddIcon />} onClick={handleItemAdd} type={'button'} />
                </Flex>
            </Panel>

            {/* <LineItems
                onAdd={}
                onEdit={}
                onDelete={}
                items={lineItems}
            /> */}
            
            <Flex justifyContent="flex-end">
                <Button type="button" iconOnly={<RestoreIcon />} onClick={handleReset} actionType={"destructive"} marginRight="medium" />
                <Button type="submit" isLoading={isLoading}>Send</Button>
            </Flex>
        </StyledForm>
    );
};
const LineItem = forwardRef(({onEdit, onDelete, item, isFirst, isLast}: {
        onEdit?: (item: ShipmentLineItemFormData) => void,
        onDelete?: () => void,
        item: ShipmentLineItemFormData
        isFirst: boolean,
        isLast: boolean
}, ref: RefObject<HTMLInputElement>) => {
// const LineItem = ({ onAdd, onEdit, onDelete, item, isNew }: LineItemProps) => {
    // const [itemData, setItemData] = useState<ShipmentLineItemFormData>(item);
    // const [isEditing, setEditMode] = useState<boolean>(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name: formName, value } = event?.target;
        const newItem = {...item};
        newItem[formName] = value;
        onEdit(newItem);
        // setItemData(prevForm => ({ ...prevForm, [formName]: value }));
    };
    
    const saveChanges = () => {
        // setEditMode(false);
        // onEdit(itemData);
    };

    // const buttons : Array<ButtonGroupAction> = isEditing
    //     ? [
    //         { iconOnly: <CheckIcon />, onClick: saveChanges /*, variant: 'primary'*/ },
    //         { icon: <CloseIcon />, onClick: () => setEditMode(false) /*, variant: 'secondary'*/ }
    //     ]
    //     : [
    //         { icon: <EditIcon />, onClick: () => setEditMode(true) },
    //         { icon: <DeleteIcon />, onClick: onDelete }
    //     ];

    // const ref: RefObject<HTMLInputElement> = createRef();

    return (
        <Box marginBottom={'medium'}>
            <FormGroup>
                <Input
                    // error={errors?.itemSku}
                    type="text"
                    label="Sku (ITMNMB)"
                    name="itemSku"
                    required
                    value={item.itemSku}
                    onChange={handleChange}
                    // disabled={!isEditing && !isLast}
                />
                <Input
                    // error={errors?.quantity}
                    type="number"
                    label="Quantity (QTY)"
                    name="quantity"
                    required
                    min={1}
                    value={item.quantity}
                    onChange={handleChange}
                    // disabled={!isEditing && !isLast}
                />
                {/* <ButtonGroup
                    actions={buttons}
                /> */}
                { !(isFirst && isLast) && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                        <button
                            style={{alignItems: 'center', appearance: 'button', backgroundColor: '#3C64F4', borderColor: '#3C64F4', borderRadius: '.25rem', color: '#FFF', cursor: 'pointer', display: 'flex', height: '2.25rem'}}
                            type='button'
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                )}
                {/* { !(isFirst && isLast) && <Button iconOnly={<DeleteIcon />} onClick={onDelete} type={'button'} marginTop={'xLarge'} marginRight={'xxxLarge'} /> } */}
            </FormGroup>
            {/* <Flex marginBottom={'medium'}>
                { !(isFirst && isLast) && <Button iconOnly={<DeleteIcon />} onClick={onDelete} type={'button'} /> } */}

                {/* { isLast 
                    ? null
                    : isEditing
                        ? <Button iconOnly={<CheckIcon />} onClick={saveChanges} variant={'primary'} type={'button'} />
                        : <Button iconOnly={<EditIcon />} onClick={() => setEditMode(true)} variant={'secondary'} type={'button'} />
                }
                { isEditing && <Button iconOnly={<CloseIcon />} onClick={() => setEditMode(false)} type={'button'} /> }
                { !(isFirst && isLast) && !isEditing && <Button iconOnly={<DeleteIcon />} onClick={onDelete} type={'button'} /> } */}

                {/* { isLast
                    ? null
                    : isEditing
                        ? <Button iconOnly={<CloseIcon />} onClick={() => setEditMode(false)} type={'button'} />
                        : <Button iconOnly={<DeleteIcon />} onClick={onDelete} type={'button'} />
                } */}
            {/* </Flex> */}
        </Box>
    );
})

export default Form;
