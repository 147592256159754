import { Box, H3 } from '@bigcommerce/big-design';
import { ErrorMessageProps } from 'types';

const ErrorMessage = ({ error }: ErrorMessageProps) => (
    <Box backgroundColor='danger20' border='box' borderRadius='normal' padding='medium'>
        <H3>Failed to load</H3>
        {error && error.message}
    </Box>
);

export default ErrorMessage;
